.phillip {
   display: flex;
   flex-direction: column;

   h2 {
      margin-bottom: 60px;
   }

   .phillip__controls {
      display: flex;

   }

   .phillip__audio {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
         margin-bottom: 60px;
         max-width: 400px;
         width: 100%;
         cursor: pointer;
         animation: phill 4s infinite ease;

         &.party {
            animation: none;
            opacity: 1;
         }
      }
   }

   .phillip__video {
      display: flex;
      width: 300px;
      margin-left: 60px;

      video {
         width: 100%;
      }
   }

   @keyframes phill {
      0% {opacity: 1}
      50% {opacity: 0.5}
      100% {opacity: 1}
    }
    

   @keyframes poop-dance {
      from, 49% { transform-origin: -50% 100%; }
      50%, 75%, to { transform-origin: 150% 100%; }
      25% { transform: rotate(-10deg); }
      50% { transform: rotate(0deg); }
      75% { transform: rotate(10deg); }
    }
    
    .poop {
      font-size: 30px;
       
      &.party {
         animation: poop-dance .8s infinite alternate ease-in-out;
      }
    }
    
    .poop {
       display: inline-block;
    }
    
    .poop:empty::before {
      content: "💩";
    }
}

