$color: #fd7000;
$size: 12px;
$time: 1;

.dank-ass-loader {
   display: flex;
   flex-direction: column;
   align-items: center;
   
   .row {
      display: flex;
   }

   .arrow {
      width: 0; 
      height: 0;
      margin: 0 (-$size / 2);
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: ($size * 1.8) solid $color;
      animation: blink $time + s infinite;
      filter: drop-shadow(0 0 ($size * 1.5) $color);
       
      &.down {
         transform: rotate(180deg);
      }
      
      @for $i from 1 through 18 {
         &.outer-#{$i} {
            animation-delay: -($time / 18) * $i + s;
         }  
      }
   
      @for $i from 1 through 6 {
         &.inner-#{$i} {
            animation-delay: -($time / 6) * $i + s;
         }  
      }  
   }
   
   @keyframes blink {
     0% { opacity: 0.1; }
     30% { opacity: 1; }
     100% { opacity: 0.1; }
   }
}

